import React, { FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import { isLight } from '../common/helpers';
import { KnownBreakpoints } from '../common/constants';
import WarningIcon from '../assets/SvgIcons/WarningIcon';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

const styles = {
    emptyText: (theme: Theme) => ({
        ...theme.typography.h2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        marginTop: '40px',
        color: isLight(theme)
            ? theme.palette.text.primary
            : theme.palette.common.white,
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            marginTop: '100px',
        },
    }),
};

export interface NothingFoundProps {
    sx?: any;
}
export interface NothingFoundWithErrorProps {
    sx?: any;
    alertSx?: any;
    alertIcon?: JSX.Element;
    error?: string;
}

const NothingFound: FunctionComponent<NothingFoundProps> = props => {
    const theme = useTheme();
    const { sx } = props;

    return <Typography component="h2" sx={{
        ...styles.emptyText(theme),
        ...(typeof sx === 'function' ? sx(theme) : sx || {}),
    }}>
        Nothing found
    </Typography>;
};

export const NothingFoundWithError: FunctionComponent<
    NothingFoundWithErrorProps
> = props => {
    const theme = useTheme();
    const { sx } = props;
    const errorIcon = props.alertIcon || <WarningIcon />;
    const error = <>
        Nothing found, because error occurred while loading data, please, try
        to <a
            href="javascript:location.reload()"
            style={{ textDecoration: 'underline', color: '#fff' }}
        >reload</a> this page...
    </>;

    return <Box sx={{
        ...styles.emptyText(theme),
        ...(typeof sx === 'function' ? sx(theme) : sx || {}),
    }}>
        <Alert
            severity="error"
            iconMapping={{ error: errorIcon }}
            sx={ props.alertSx }
        >
            { props.error || error }
        </Alert>
    </Box>;
};

export default NothingFound;
