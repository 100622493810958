import { RX_PDF_MIME } from '../constants';

export default async function downloadPdf(
    url: string,
    filename: string,
    options?: RequestInit,
) {
    return fetch(url, options)
        .then(res => res.blob())
        .then(blob => {
            if (!(blob.size && RX_PDF_MIME.test(blob.type))) {
                return 'Unable to fetch PDF document. ' +
                    'Please, try again later. If the problem persists, ' +
                    'please, contact support for help. ' +
                    'Sorry for any inconvenience.';
            }

            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', filename);
            link.click();

            return '';
        });
}
