import React, { FunctionComponent, PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import { isLight } from '../common/helpers';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KnownBreakpoints } from '../common/constants';
import { useTheme } from '@mui/material/styles';

export interface InfoDialogProps extends PropsWithChildren {
    open: boolean;
    onClose?: () => void;
    title?: string;
}

const styles = {
    dialogPaper: (theme: Theme, isMobile: boolean) => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: isMobile ? '100%' : 'auto',
        maxWidth: isMobile ? '100%' : '500px',
        maxHeight: isMobile ? '100%' : 'unset',
        border: 0,
        borderRadius: isMobile ? 0 : '20px',
        backgroundColor: isLight(theme)
            ? theme.palette.common.white
            : theme.palette.secondary.main,
        color: isLight(theme)
            ? theme.palette.text.primary
            : theme.palette.common.white,
        boxShadow: 24,
        p: 4,
        '&:focus': {
            outline: 'none',
            border: 'none',
        },
        '& p, & img': {
            margin: '10px',
        },
    }),
    contentBox: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    dialogTitle: {
        fontWeight: 'bold',
        width: '100%',
        padding: '10px',
    },
};

const InfoModal: FunctionComponent<InfoDialogProps> = props => {
    const theme = useTheme();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );

    return <Modal
        open={ props.open }
        onClose={ props.onClose }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={{ ...styles.dialogPaper(theme, isMobile) }}>
            <Box sx={ styles.contentBox }>
                { props.title && <Typography
                    id="modal-modal-title"
                    component="h3"
                    sx={ styles.dialogTitle }
                >{ props.title }</Typography> }
                { props.children }
            </Box>
        </Box>
    </Modal>;
};

export default InfoModal;
